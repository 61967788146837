import axios from "axios";
import { getCrmContact } from "./crm.service";

const apiUrl = process.env.REACT_APP_ENV;

export const userVerification = {
  async emailSignIn(email: string, utmData: any, lang: string): Promise<any> {
    // console.log(utmData)
    try {
      const response = await axios.post(
        `${apiUrl}/api/user/email-registeration`,
        { email, utmData, lang }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to register email"); // Throw an error with a custom message
      }
    } catch (err) {
      throw err; // Re-throw the caught error
    }
  },
  async signInWithOtp(otp: string, token: string): Promise<any> {
    // console.log("🚀 ~ file: service.ts:17 ~ signInWithOtp ~ otp:", otp, token)
    try {
      const response = await axios.post(
        `${apiUrl}/api/user/verify-otp`,
        { otp },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // console.log("🚀 ~ file: service.ts:20 ~ signInWithOtp ~ response:", response)
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      throw new Error(String(err)); // Re-throw the caught error
    }
  },
  async phoneNumberSignIn(
    phone: string,
    utmData: any,
    lang: string
  ): Promise<any> {
    // console.log(utmData)
    try {
      const response = await axios.post(`${apiUrl}/api/user/login-whatsapp`, {
        phone,
        utmData,
        lang,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to register phone number"); // Throw an error with a custom message
      }
    } catch (err) {
      throw err; // Re-throw the caught error
    }
  },

  async getUserGeoLocation(): Promise<boolean> {
    try {
      const { data } = await axios.get("https://ipapi.co/json/");
      const countryCode = data.country;
      if (["US", "GB", "DE", "FR"].includes(countryCode)) {
        // Logic for targeted countries
        return true;
      }
      return false;
    } catch (err: any) {
      console.error("Error fetching user location:", err.message);
      return false;
    }
  },
};

export const getPurchasePlanLink = async (
  userPhone: string,
  planId: string
) => {
  try {
    if (userPhone === "") {
      throw new Error("Phone number is missing");
    }
    const response = await axios.get(
      `https://app.unichat.one/order/plan/sales/subscription/?userPhone=${userPhone}&planId=${planId}`
    );
    // console.log("🚀 ~ getPurchasePlanLink ~ response:", response)
    // console.log("🚀 ~ getPurchasePlanLink ~ apiUrl:", apiUrl)
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to fetch purchase link"); // Throw an error with a custom message
    }
  } catch (err) {
    throw err; // Re-throw the caught error
  }
};

// TODO: Create the add crm contact and ticket function
// export const AddCrmTicket = async (contact: {msg: string, email: string, name: string}) => {
//   try{
//     const response = await getCrmContact(contact)
