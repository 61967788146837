import React, { useCallback, useEffect, useRef, useState } from "react";
// Styles
import Styles from "./WhatsAppLp.module.scss";
import "animate.css";
// Components
import UniContact from "../../cmps/UniContact/UniContact.cmp";

import StartFreeButton from "../../cmps/StartFreeButton/StartFreeButton.cmp";
import FiguresCards, {
  FigureCard,
} from "../../cmps/FiguresCard/FiguresCard.cmp";

// Util helpers
import {
  unicornSVG,
  gpt4Motion,
  claudeImage,
  whatsAppAndChatExample,
  unichatHeroNoWhatsapp,
  HeroWhatsAppNew,
} from "../../utils/assetsImports";
import {
  // accordionsData,
  // getPricingCardsData,
  getFigureCardsData,
  getServiceDataCardsData,
} from "../../utils/HardCodedData";
// Sections
import StartFreeAndImg from "../../PageSections/StartFreeAndImg/StartFreeAndImg.cmp";
import PlanCardsSection from "../../PageSections/PlanCardsSection/PlanCardsSection.cmp";
import WhatsappExamplesSection from "../../PageSections/WhatsappExamplesSection/WhatsappExamplesSection.cmp";
import FaqSection from "../../PageSections/FaqSection/FaqSection.cmp";
// Utils
import {
  extractUserData,
  removeAccessibilityComponent,
  storage,
} from "../../utils/utils";
// Recoil
import { useRecoilState, useSetRecoilState } from "recoil";
import { clientPhoneNumber } from "../../store/atoms/contactUsAtom";
// Imports
import { useTranslation } from "react-i18next";
import {
  languageModel,
  moneyBackGuarantee,
  redirectedToThankYouPage,
} from "../../store/atoms/generalAtoms";
import { Helmet } from "react-helmet";
import {
  buttonAndLabelStyles,
  buttonAndLabelStylesWhatsAppSection,
} from "../../utils/constant";

import { ServiceDataCardsInterface } from "../../cmps/ServiceDataCard/ServiceDataCard.cmp";
import LetsChatModal from "../../cmps/LetsChatModal/LetsChatModal.cmp";
import VideoSection from "../../PageSections/VideoSection/VideoSection";
import BillindModal from "../../cmps/BillingModal/BillindModal.cmp";
import MoneyBackSection from "../../cmps/MoneyBackSection/MoneyBackSection";

const BillingPlans = {
  free: "",
  basic: "BSIC799",
  premium: "PRIM1499",
  pro: "PRO1999",
};

const ILPricing = {
  free: 0,
  basic: 29.6,
  premium: 49.9,
  pro: 74.99,
};

export default function WhatsAppPage() {
  const [figuresData, setFiguresData] = useState<FigureCard[]>(
    getFigureCardsData()
  );
  const homePageRef = useRef(null);
  const [recoilLangModel, setRecoilLangModel] = useRecoilState(languageModel);
  const [serviceDataCardsInfo, setServiceDataCardsInfo] = useState<
    ServiceDataCardsInterface[]
  >(getServiceDataCardsData());
  const [userPhone, setUserPhone] = useRecoilState(clientPhoneNumber);
  const { t, i18n } = useTranslation();
  const [isBelowThreshold, setIsBelowThreshold] = useState(
    window.innerWidth < 1378
  );
  const setThankYouPage = useSetRecoilState<boolean>(redirectedToThankYouPage);
  const [openWhatsAppModal, setOpenWhatsAppModal] = useState<boolean>(false);
  const user_id = storage.loadFromStorage("userId");
  const [openBillingModal, setOpenBillingModal] = useState<boolean>(false);

  const [isMoneyBackGuarantee, setIsMoneyBackGuarantee] =
    useRecoilState(moneyBackGuarantee);
  const setPageLanguage = async () => {
    await i18n.changeLanguage("he-IL");
    setRecoilLangModel("he-IL");
  };

  const handleOpenWhatsAppModal = () => {
    setOpenWhatsAppModal(!openWhatsAppModal);
  };

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set whether screen width is below threshold
      setIsBelowThreshold(window.innerWidth < 1378);
    }

    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Detach event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  // Gets clients data from url params as soon as he lands in that page
  useEffect(() => {
    // checkToken(navigate)
    const { FAQ } = extractUserData.extractUrlFAQ();
    // console.log("🚀 ~ file: UniHomePage.cmp.tsx:89 ~ useEffect ~ FAQ:", FAQ)
    if (FAQ) {
      const mainSectionDiv: HTMLElement | null = document.getElementById(`faq`);
      // console.log(mainSectionDiv)
      if (mainSectionDiv) {
        mainSectionDiv!.scrollIntoView({
          behavior: "instant" as ScrollBehavior,
          block: "start",
        });
        // setTimeout(() => window.scrollBy(0, 0));
      }
    }

    // setPageLanguage();
    // Gets the data from the url params
    const clientUrlParams = extractUserData.extractUrlParams();
    // Gets the data from the cookies
    const gaClientId = extractUserData.getGAClientId();

    // If the params contains userPhone, it will set a global state with it and save it to local storage.
    let urlUserPhone: string =
      extractUserData.extractUrlPhoneNumber().userPhone || "";
    if (urlUserPhone !== "") {
      // console.log(urlUserPhone)
      storage.saveToStorage("userPhone", `+${urlUserPhone}`);
      setUserPhone(`+${urlUserPhone}`);
    }
    // Saves all the data as an object to local storage.
    storage.saveToStorage("clientFullData", { ...clientUrlParams, gaClientId });
    // console.log(recoilLangModel)
    setThankYouPage(true);
    removeAccessibilityComponent(i18n.language);
    // setPageLanguage();
    setIsMoneyBackGuarantee(true);
    return () => {
      setThankYouPage(false);
      setIsMoneyBackGuarantee(false);
    };
  }, []);

  const chooseBillingPlan = (annualBilling: boolean) => {
    // console.log("🚀 ~ file: PricingPlanCards.cmp.tsx:60 ~ chooseBillingPlan ~ annualBilling:", annualBilling, togglePlanState)
    // setChosenBillingPlan(BillingPlans[`${planType}`])

    storage.saveToSessionStorage("billingPlanParams", {
      planId: BillingPlans.pro,
      yearly: "false",
    });
  };

  const onclickOpenModal = useCallback(
    (annualBilling: boolean = false) => {
      // const currency = !recoilLangModel.toLocaleLowerCase().includes('he') ? "USD" : "ILS";
      const currency = "ILS";
      const price = ILPricing["pro"];

      const dataLayerPlanData = {
        event: "add_to_cart",
        user_id: user_id,
        // user_id: userPhoneParam.replace(/^\+/, ""),
        currency: currency,
        value: price,
        item_name: "PRO",
        items: [
          {
            item_id: BillingPlans["pro"],
            quantity: 1,
            price: price,
            currency: currency,
          },
        ],
      };

      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push(dataLayerPlanData);
      (window as any).fbq("track", "AddToCart");
      // sendEvent('add_to_cart', planType, currency, price);
      // console.log("🚀 ~ file: PricingPlanCards.cmp.tsx:112 ~ onclickOpenModal ~ (window as any).dataLayer:", (window as any).dataLayer)
      storage.saveToSessionStorage("dataLayerCheckOutData", dataLayerPlanData);

      chooseBillingPlan(annualBilling);
      setOpenBillingModal(true);
    },
    [user_id]
  );

  return (
    <>
      <Helmet>
        <title>{t("homePageHelmet.title")}</title>
        <meta
          name="description"
          content={`${t("homePageHelmet.description")}`}
        ></meta>
      </Helmet>
      <div
        ref={homePageRef}
        id="/"
        className={`${Styles.UniHomePageMainContainer} animate__animated animate__fadeIn `}
      >
        {/* <section className={Styles.whatsAppPageHeader}>
          {`${t("firstWeekFree")}`}
        </section> */}
        <StartFreeAndImg
          // imgStyle={{ height: "100%", width: "100%", maxWidth: "700px" }}
          buttonAndLabelStyles={
            isBelowThreshold
              ? {
                  ...(recoilLangModel.includes("he")
                    ? buttonAndLabelStyles["he"]
                    : buttonAndLabelStyles["en"]),
                  margin: "0 0 4rem",
                  gap: "5rem",
                }
              : recoilLangModel.includes("he")
              ? buttonAndLabelStyles["he"]
              : buttonAndLabelStyles["en"]
          }
          containerStyles={{ alignItems: "center" }}
          imagesContainerStyles={{ width: "83%" }}
          reverse={false}
          buttonStyles={
            !isBelowThreshold
              ? {
                  margin: "0",
                  backgroundColor: "#FD4766",
                  color: "white",
                  // width: "350px",
                  padding: "0 1rem",
                  fontSize: "36px",
                }
              : {
                  // margin: "0 0 5rem",
                  backgroundColor: "#FD4766",
                  color: "white",
                  // width: "350px",
                  fontSize: "36px",
                }
          }
          paragraphText={t("WhatsAppLpPage.FirstSection.paragraphText")}
          imgName={[HeroWhatsAppNew]}
          // buttonTitle={t("WhatsAppPage.FirstSection.buttonTitle")}
          buttonTitle={t("WhatsAppLpPage.FirstSection.buttonTitle")}
          onClickMethod={onclickOpenModal}

          // phoneInput
          // onClickMethod={handleOpenWhatsAppModal}
          // phoneInput
        />
        <VideoSection
          onclickOpenModal={onclickOpenModal}
          paragraphText={t("WhatsAppLpPage.SecondSection.paragraphText")}
          buttonTitle={t("WhatsAppLpPage.SecondSection.buttonTitle")}
        />
        <MoneyBackSection />
        <StartFreeAndImg
          containerStyles={{
            alignItems: "flex-end",
            flexDirection: isBelowThreshold ? "column" : "",
          }}
          buttonAndLabelStyles={
            isBelowThreshold
              ? {
                  ...(recoilLangModel.includes("he")
                    ? buttonAndLabelStyles["he"]
                    : buttonAndLabelStyles["en"]),
                  flexDirection: "column",
                }
              : recoilLangModel.includes("he")
              ? buttonAndLabelStyles["he"]
              : buttonAndLabelStyles["en"]
          }
          buttonStyles={isBelowThreshold ? { margin: "5rem 0 0" } : {}}
          imgStyle={{ height: "100%", width: "100%", maxWidth: "700px" }}
          paragraphText={t("WhatsAppLpPage.ThirdSection.paragraphText")}
          imgName={[unicornSVG]}
          negative
          // buttonTitle={t("FreeTrialPage.FourthSection.buttonTitle")}
          buttonTitle={t("WhatsAppLpPage.ThirdSection.buttonTitle")}
          // onClickMethod={handleOpenWhatsAppModal}
          // scrollToTop
          onClickMethod={onclickOpenModal}
        />
        <StartFreeAndImg
          // buttonAndLabelStyles={buttonAndLabelStyles[String(recoilLangModel).slice(0, 2) as keyof LanguageStyles]}
          containerStyles={{ flexDirection: isBelowThreshold ? "column" : "" }}
          buttonAndLabelStyles={
            isBelowThreshold
              ? {
                  ...(recoilLangModel.includes("he")
                    ? buttonAndLabelStyles["he"]
                    : buttonAndLabelStyles["en"]),
                  flexDirection: "column",
                }
              : recoilLangModel.includes("he")
              ? buttonAndLabelStyles["he"]
              : buttonAndLabelStyles["en"]
          }
          imgStyle={{ height: "100%", width: "100%" }}
          paragraphText={t("WhatsAppLpPage.FourthSection.paragraphText")}
          imgName={[gpt4Motion]}
          // buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          buttonStyles={!isBelowThreshold ? { margin: "0" } : {}}
          // buttonTitle={t("WhatsAppPage.SecondSection.buttonTitle")}
          buttonTitle={t("WhatsAppLpPage.FourthSection.buttonTitle")}
          negative
          reverse
          // onClickMethod={handleOpenWhatsAppModal}
          onClickMethod={onclickOpenModal}
          // scrollToTop
        />
        <StartFreeAndImg
          containerStyles={{ flexDirection: isBelowThreshold ? "column" : "" }}
          buttonAndLabelStyles={
            isBelowThreshold
              ? {
                  ...(recoilLangModel.includes("he")
                    ? buttonAndLabelStyles["he"]
                    : buttonAndLabelStyles["en"]),
                  flexDirection: "column",
                  marginBottom: "4rem",
                }
              : recoilLangModel.includes("he")
              ? buttonAndLabelStyles["he"]
              : buttonAndLabelStyles["en"]
          }
          imgStyle={{ height: "100%", width: "100%", maxWidth: "1000px" }}
          paragraphText={t("WhatsAppLpPage.FifthSection.paragraphText")}
          imgName={[claudeImage]}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : {}}
          // reverse
          // buttonTitle={t("FreeTrialPage.ThirdSection.buttonTitle")}
          buttonTitle={t("WhatsAppLpPage.FifthSection.buttonTitle")}
          // onClickMethod={handleOpenWhatsAppModal}
          // scrollToTop
          onClickMethod={onclickOpenModal}
        />

        <WhatsappExamplesSection
          // firstMessage={whatsAppExampleFirstMessage}
          // secondMessage={whatsAppExampleSecondMessage}
          buttonTitle={t("WhatsAppLpPage.SixthSection.buttonTitle")}
          buttonAndLabelStyles={
            recoilLangModel.includes("he")
              ? buttonAndLabelStylesWhatsAppSection["he"]
              : buttonAndLabelStylesWhatsAppSection["en"]
          }
          // buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          title={t("WhatsAppLpPage.SixthSection.paragraphText")}
          photo={whatsAppAndChatExample}
          imgStyles={{ width: "unset" }}
          onClickMethod={onclickOpenModal}
          // onClickMethod={handleOpenWhatsAppModal}
          // scrollToTop
        />

        <div id="about" className={Styles.ForthRowFiguresContainer}>
          <p style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                textAlign: recoilLangModel.includes("he") ? "right" : "left",
                fontSize: "clamp(0.7rem, 6vw, 4rem)",
                fontWeight: "300",
              }}
            >
              <span dir={recoilLangModel.includes("he") ? "rtl" : "ltr"}>
                {t("WhatsAppLpPage.SeventhSection.firstSpan")}
              </span>
            </span>
            <span
              style={{
                textAlign: recoilLangModel.includes("he") ? "right" : "left",
                fontSize: `${
                  recoilLangModel.includes("he")
                    ? "clamp(1.7rem, 11vw, 10rem)"
                    : "clamp(1.5rem, 8vw, 7rem)"
                }`,
                whiteSpace: "nowrap",
              }}
            >
              {t("WhatsAppLpPage.SeventhSection.secondSpan")}
            </span>
            {/* <span
              style={{
                textAlign: recoilLangModel.includes("he") ? "left" : "right",
                fontSize: "clamp(0.7rem, 6vw, 4rem)",
                fontWeight: "500",
              }}
            >
              {t("FreeTrialPage.SixthSection.thirdSpan")}
            </span> */}
          </p>
          <div className={Styles.FiguresContainer}>
            {figuresData.map((figure: any) => {
              return (
                <div key={`${figure.data}_${Math.random() * 50}`}>
                  <FiguresCards
                    asset={figure.asset}
                    number={figure.number}
                    data={figure.data}
                    symbol={figure.symbol}
                  />
                </div>
              );
            })}
          </div>
          <StartFreeButton
            // title={t("WhatsAppPage.StartFreeButton")}
            title={t("WhatsAppLpPage.EighthSection.buttonTitle")}
            negative
            customStyle={{
              backgroundColor: "#FD4766",
              border: "none",
              marginTop: "3rem",
            }}
            // onClickMethod={handleOpenWhatsAppModal}
            // scrollToTop
            onClickMethod={onclickOpenModal}
          />
        </div>
        <PlanCardsSection
          startFreeFunction={handleOpenWhatsAppModal}
          startFree
        />
        {/* <div className={Styles.FifthRowMainContainer}>
                    <p className={recoilLangModel.toLocaleLowerCase().includes('he') ? Styles.HebClass : ""}>
                        {t('homePageFifthSection.p')}
                    </p>
                    <StartFreeButton
                        title={t('StartFreeButton.StartForFree')}
                        negative
                        customStyle={{ backgroundColor: "#FD4766", border: "none" }}
                    />
                    <ServicesCompare />
                    <div className={Styles.ServiceDataCardsContainer}>{serviceDataCardsInfo.map((cardData: ServiceDataCardsInterface) => {
                        return <ServiceDataCard key={cardData.headerTitle} imgSource={cardData.imgSource} headerTitle={cardData.headerTitle} paragraphText={cardData.paragraphText} />
                    })}</div>
                </div> */}
        <FaqSection notFreePage />
        <UniContact scrollToTop />
        {openWhatsAppModal && (
          <LetsChatModal chatModalOpenState={handleOpenWhatsAppModal} />
        )}
      </div>
      {openBillingModal && (
        <BillindModal
          setOpenBillingModal={setOpenBillingModal}
          isWhatsApp
          isMoneyBackGuarantee={true}
        />
      )}
    </>
  );
}
